import React, { useContext } from "react";
import { Link } from "gatsby";
import {
  AiFillLinkedin,
  AiFillInstagram,
  AiFillBehanceSquare,
} from "react-icons/ai";
import { FormattedMessage } from "react-intl";
import LocaleContext from "../../context/locale";
import localeSlug from "../../utils/localeSlug";

function Button({ to, children }) {
  return (
    <Link
      to={to}
      className="hover:text-gray-900 hover:bg-white transition-all relative w-44 py-3 px-5 text-sm uppercase border border-gray-300 rounded-full tracking-wide inline-block text-center"
    >
      {children}
    </Link>
  );
}

function Home({ messages }) {
  const { langKey } = useContext(LocaleContext);

  return (
    <div className="flex h-screen text-white text-center">
      <div className="m-auto">
        <h1 className="text-5xl tracking-widest mb-4">{messages.title}</h1>
        <h2 className="font-roboto text-lg text-gray-300 tracking-wider my-4">
          {messages.subtitle}
        </h2>
        <div className="my-11 font-roboto text-gray-300 flex flex-col items-center space-y-3 sm:block sm:space-x-3 sm:space-y-0">
          <Button to={localeSlug(langKey, "/works")}>
            <FormattedMessage id="menuWorks" />
          </Button>
          <Button to={localeSlug(langKey, "/photos")}>
            <FormattedMessage id="menuPhotos" />
          </Button>
        </div>
        <div className="flex justify-center text-2xl">
          <a
            href="https://www.linkedin.com/in/martinpettinari/"
            target="_blank"
            rel="noreferrer"
          >
            <AiFillLinkedin className="mx-2" />
          </a>
          <a
            href="https://www.instagram.com/tinchoman/"
            target="_blank"
            rel="noreferrer"
          >
            <AiFillInstagram className="mx-2" />
          </a>
          <a
            href="https://www.behance.net/Tinchoman"
            target="_blank"
            rel="noreferrer"
          >
            <AiFillBehanceSquare className="mx-2" />
          </a>
        </div>
      </div>
    </div>
  );
}

export default Home;
